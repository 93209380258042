<template>
  <section>
    <b-card-title
      class="py-1 d-flex mb-0"
      style="position: sticky; top: 45px; z-index: 100; background: white;"
    >
      <feather-icon
        v-if="!isMobileView"
        icon="ToolIcon"
        size="24"
        class="mx-50"
      />
      <span :class="isMobileView ? 'font-medium-2 text-nowrap' : ''">
        {{ $t('agency.configs.title') }} {{ $t('agency.agencies') }}:
        <kbd class="text-dark ml-25">{{ agencyToEdit?.agencyCode }}</kbd>
      </span>
    </b-card-title>

    <!-- SECTION Permission -->
    <b-card
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-50"
      no-body
      class="border mb-1 mt-0"
    >
      <template #header>
        <div class="d-flex-between w-100">
          <h5 class="m-0">
            {{ $t('agency.configs.permissionTitle') }}
          </h5>
          <div>
            <BButton
              v-if="!isEditPermission"
              variant="outline-primary"
              @click="isEditPermission = !isEditPermission"
            >
              {{ $t('edit') }}
            </BButton>
            <BButton
              v-else
              variant="primary"
              :disabled="loadingEditPermission"
              @click="handleEditPermission"
            >
              <b-spinner
                v-if="loadingEditPermission"
                variant="light"
                label="Text Centered"
                small
                tag="span"
              />
              {{ loadingEditPermission ? $t('saving') : $t('save') }}
            </BButton>
          </div>
        </div>
      </template>
      <b-card-body class="py-0 px-2">
        <IAmOverlay :loading="loadingEditPermission">
          <!-- ANCHOR bookingLimit -->
          <b-form-group
            v-if="showBookingLimit"
            class="mt-50"
            :label="$t('agency.configs.bookingLimit')"
          >
            <b-form-checkbox
              v-for="(bLimit, bLimitIndex) in bookingLimit"
              :key="`${bLimit?.airlineSource}-${bLimitIndex}`"
              class="custom-control-success"
              name="check-button"
              inline
              :disabled="!isEditPermission"
              :checked="bLimit?.enableBooking"
              @change="(checked) => changeBookingLimit(checked, bLimit.airlineSource)"
            >
              {{ $te(`flight.sourceName.${bLimit?.airlineSource.toUpperCase()}`)
                ? $t(`flight.sourceName.${bLimit?.airlineSource.toUpperCase()}`)
                : bLimit?.airlineSource.toUpperCase() }}
            </b-form-checkbox>
          </b-form-group>
          <!-- ANCHOR issueLimit-->
          <b-form-group
            v-if="showIssueRefundVoidLimit"
            :label="$t('agency.configs.issueLimit')"
            class="mt-1"
          >
            <b-form-checkbox
              v-for="(issue, issueIndex) in issueLimit"
              :key="`${issue?.airlineSource}-${issueIndex}`"
              class="custom-control-success"
              name="check-button"
              inline
              :disabled="!isEditPermission"
              :checked="issue?.allowance"
              @change="(checked) => changeIssueLimit(checked, issue.airlineSource)"
            >
              {{ $te(`flight.sourceName.${issue?.airlineSource.toUpperCase()}`)
                ? $t(`flight.sourceName.${issue?.airlineSource.toUpperCase()}`)
                : issue?.airlineSource.toUpperCase() }}
            </b-form-checkbox>
          </b-form-group>
          <!-- ANCHOR refundLimit -->
          <b-form-group
            v-if="showIssueRefundVoidLimit && agencyIsF2"
            :label="$t('agency.configs.refundLimit')"
            class="mt-1"
          >
            <b-form-checkbox
              v-for="(refund, refundIndex) in refundLimit"
              :key="`${refund?.airlineSource}-${refundIndex}`"
              class="custom-control-success"
              name="check-button"
              inline
              :disabled="!isEditPermission"
              :checked="refund?.allowance"
              @change="(checked) => changeRefundLimit(checked, refund.airlineSource)"
            >
              {{ $te(`flight.sourceName.${refund?.airlineSource.toUpperCase()}`)
                ? $t(`flight.sourceName.${refund?.airlineSource.toUpperCase()}`)
                : refund?.airlineSource.toUpperCase() }}
            </b-form-checkbox>
          </b-form-group>
          <!-- ANCHOR voidLimit -->
          <b-form-group
            v-if="showIssueRefundVoidLimit && agencyIsF2"
            :label="$t('agency.configs.voidLimit')"
            class="mt-1"
          >
            <b-form-checkbox
              v-for="(voidItem, voidIndex) in voidLimit"
              :key="`${voidItem?.airlineSource}-${voidIndex}`"
              class="custom-control-success"
              name="check-button"
              inline
              :disabled="!isEditPermission"
              :checked="voidItem?.allowance"
              @change="(checked) => changeVoidLimit(checked, voidItem.airlineSource)"
            >
              {{ $te(`flight.sourceName.${voidItem?.airlineSource.toUpperCase()}`)
                ? $t(`flight.sourceName.${voidItem?.airlineSource.toUpperCase()}`)
                : voidItem?.airlineSource.toUpperCase() }}
            </b-form-checkbox>
          </b-form-group>
        </IAmOverlay>
      </b-card-body>
    </b-card>
    <!-- !SECTION -->

    <!-- SECTION Feather -->
    <b-card
      v-if="!isRoleF3"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1 pb-0"
      class="border my-1"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('agency.configs.featureTitle') }}
        </h6>
      </template>
      <b-card-body class="p-0 d-flex-center justify-content-start mt-1">
        <b-row class="w-100 gap-3">
          <!-- ANCHOR enableLowFareTracking -->
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.enableLowFareTracking"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="changeActionSetLowFareTracking(agencyToEdit.id)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.enableSetLowFare') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR enableBookingGroup -->
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.enableBookingGroup"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="toggleEnableBookingGroup(agencyToEdit.id)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.enableBookingGroup') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR sendMailAirline -->
          <b-col
            v-if="agencyToEdit?.parentAgency && agencyToEdit?.parentAgency?.id !== 1000000 && isRoleF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.sendMailAirline"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              :disabled="isF3Retail"
              @click.native.prevent="changeSendMailAirline(agencyToEdit.id)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.sendMailAirlineOn') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR sendPhoneAirline -->
          <b-col
            v-if="agencyToEdit?.parentAgency && agencyToEdit?.parentAgency?.id !== 1000000 && isRoleF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.sendPhoneAirline"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              :disabled="isF3Retail"
              @click.native.prevent="changeSendPhoneAirline(agencyToEdit.id)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.sendPhoneAirline') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR quick name -->
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.enableQuickName"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="changeEnableQuickName(agencyToEdit.id)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.enableQuickName') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR rebook -->
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.enableRebook"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="changeEnableRebook(agencyToEdit.id)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.enableRebook') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR limit time search -->
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="6"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.limitTimeSearch.enable"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="changeLimitTimeSearch(agencyToEdit.id, !agencyToEdit.limitTimeSearch.enable, agencyToEdit.limitTimeSearch.seconds)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.limitTimeSearch') }}
                <em>(giây)</em>
              </h5>
            </b-form-checkbox>

            <div
              v-if="agencyToEdit.limitTimeSearch.enable"
              class="d-flex"
            >
              <BFormInput
                v-model="agencyToEdit.limitTimeSearch.seconds"
                type="number"
                min="0"
                max="30"
                style="width: fit-content"
              />
              <div
                v-if="agencyToEdit.limitTimeSearch.seconds !== agenciesData.limitTimeSearch.seconds"
                class="d-flex flex-nowrap mx-50 gap-2"
              >
                <b-button
                  class="text-center px-75"
                  variant="flat-success"
                  @click="changeLimitTimeSearch(agencyToEdit.id, agencyToEdit.limitTimeSearch.enable, agencyToEdit.limitTimeSearch.seconds)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  class="text-center px-75"
                  variant="flat-danger"
                  @click="agencyToEdit.limitTimeSearch.seconds = agenciesData.limitTimeSearch.seconds"
                >
                  <feather-icon
                    icon="XIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>

          <!-- ANCHOR limit pax booking -->
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="6"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              v-remove-non-numeric-chars.allNumber
              :checked="agencyToEdit.limitPaxBooking.enable"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="changeLimitPaxBooking(agencyToEdit.id, !agencyToEdit.limitPaxBooking.enable, agencyToEdit.limitPaxBooking.paxLength)"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.limitPaxBooking') }}
              </h5>
            </b-form-checkbox>
            <div
              v-if="agencyToEdit.limitPaxBooking.enable"
              class="d-flex"
            >
              <BFormInput
                v-model="agencyToEdit.limitPaxBooking.paxLength"
                type="number"
                min="1"
                max="9"
                style="width: fit-content"
              />
              <div
                v-if="agencyToEdit.limitPaxBooking.paxLength !== agenciesData.limitPaxBooking.paxLength"
                class="d-flex flex-nowrap mx-50 gap-2"
              >
                <b-button
                  class="text-center px-75"
                  variant="flat-success"
                  @click="changeLimitPaxBooking(agencyToEdit.id, agencyToEdit.limitPaxBooking.enable, agencyToEdit.limitPaxBooking.paxLength)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  class="text-center px-75"
                  variant="flat-danger"
                  @click="agencyToEdit.limitPaxBooking.paxLength = agenciesData.limitPaxBooking.paxLength"
                >
                  <feather-icon
                    icon="XIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- !SECTION -->

    <!-- SECTION - fee configs -->
    <b-card
      v-if="isRoleF2 && agencyIsF3"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1 pb-0"
      class="border my-1"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('agency.configs.feeTitle') }}
        </h6>
      </template>
      <b-row class="w-100 gap-3">
        <!-- ANCHOR - hideFee -->
        <b-col
          cols="12"
          md="auto"
          class="d-flex-center justify-content-start mt-75"
        >
          <b-form-checkbox
            :checked="agencyToEdit.hideFee"
            class="custom-control-success"
            name="check-button"
            switch
            inline
            :disabled="isF3Retail"
            @click.native.prevent="changeHideFeeConfig(agencyToEdit.id)"
          >
            <h5 class="mb-0 mt-25">
              {{ $t('agency.configs.hideFee') }}
            </h5>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <!-- !SECTION -->

    <!-- SECTION fee custom -->
    <b-card
      v-if="!isRoleF3"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-50 mb-1"
      class="border mb-0"
      no-body
    >
      <template #header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <h6 class="text-black m-0">
            {{ $t('agency.agenciesFeeOnly', { floor: agencyIsF2 ? 'F2' : agencyIsF3 ? 'F3' : '' }) }}
          </h6>

          <b-button
            v-if="isShowChangeFeeAgencyConfigBtn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary.window
            size="sm"
            variant="outline-info"
            class="btn-icon mr-1 py-25 px-50 d-flex-center"
            @click="editAgencyFee"
          >
            <img src="@icons/edit.svg">
            <span
              v-if="!isMobileView"
              class="font-medium-2 ml-25"
              style="margin-top: 1px;"
            >
              Thay đổi phí
            </span>
          </b-button>
        </div>
      </template>

      <div
        v-if="agencyToEdit?.agencyFee"
        class="mx-1"
      >
        <div
          v-if="!agencyToEdit.applyAgencyFee"
          class="text-center"
        >
          <b-alert
            show
            variant="info"
            :class="`py-50 px-1 shadow-sm fw-700 d-flex-center ${isMobileView ? 'flex-column' : ''}`"
          >
            <b>Đại lý này đang sử dụng cấu hình phí chung</b>
          </b-alert>
          <b-alert
            show
            variant="warning"
            :class="`py-50 px-1 shadow-sm fw-700 d-flex-center ${isMobileView ? 'flex-column' : ''}`"
          >
            <feather-icon
              :class="`text-warning mr-md-1`"
              icon="AlertTriangleIcon"
              size="20"
            />
            <b>Chưa áp dụng phí xuất vé riêng</b>
          </b-alert>
        </div>

        <b-tabs
          v-if="agencyToEdit.applyAgencyFee"
          pills
        >
          <b-tab active>
            <template #title>
              <feather-icon
                icon="MapPinIcon"
                size="16"
                class="mr-50"
              />
              <span>{{ $t('fee.domesticTicket') }}</span>
            </template>

            <b-table
              responsive
              striped
              hover
              small
              :empty-text="$t('noMatchingResult')"
              :items="getItemsFee(agenciesData.agencyFee, 'INLAND')"
            >
              <template #head(distributor)>
                <span>{{ $t('fee.distributor') }}</span>
              </template>
              <template #head(feeType)>
                {{ $t('fee.feeType') }}
              </template>
              <template #head(ticketType)>
                {{ $t('fee.ticketType') }}
              </template>
              <template #head(amount)>
                {{ $t('fee.amount') }}
              </template>

              <template #cell(distributor)="{ item }">
                <div class="d-flex-center gap-1 font-weight-bolder">
                  <div
                    style="width: 50px"
                    class="d-flex-center"
                  >
                    <IAmLogoAirline
                      :airline="item.distributor.toUpperCase()"
                      :height="30"
                    />
                  </div>
                  <span class="flex-1 fw-700">
                    {{ $te(`flight.airlines.${item.distributor.toUpperCase()}`)
                      ? $t(`flight.airlines.${item.distributor.toUpperCase()}`)
                      : '' }}
                    ({{ $te(`flight.sourceName.${item.distributor.toUpperCase()}`)
                      ? $t(`flight.sourceName.${item.distributor.toUpperCase()}`)
                      : item.distributor.toUpperCase() }})
                  </span>
                </div>
              </template>
              <template #cell(feeType)="data">
                {{ $t(`fee.${data.item.feeType}`) }}
              </template>
              <template #cell(ticketType)="data">
                {{ $t(`fee.${data.item.ticketType}`) }}
              </template>
              <template #cell(amount)="data">
                <span class="fw-700"> {{ formatCurrency(data.item.amount) }} </span>
              </template>
            </b-table>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon
                icon="NavigationIcon"
                size="16"
                class="mr-50"
              />
              <span>{{ $t('fee.internationalTicket') }}</span>
            </template>

            <b-table
              responsive
              striped
              hover
              small
              :empty-text="$t('noMatchingResult')"
              :items="getItemsFee(agenciesData.agencyFee, 'INTERNATIONAL')"
            >
              <template #head(distributor)>
                <span>{{ $t('fee.distributor') }}</span>
              </template>
              <template #head(feeType)>
                {{ $t('fee.feeType') }}
              </template>
              <template #head(ticketType)>
                {{ $t('fee.ticketType') }}
              </template>
              <template #head(amount)>
                {{ $t('fee.amount') }}
              </template>

              <template #cell(distributor)="{ item }">
                <div class="d-flex-center gap-1 font-weight-bolder">
                  <div
                    style="width: 50px"
                    class="d-flex-center"
                  >
                    <IAmLogoAirline
                      :airline="item.distributor.toUpperCase()"
                      :height="30"
                    />
                  </div>
                  <span class="flex-1 fw-700">
                    {{ $te(`flight.airlines.${item.distributor.toUpperCase()}`)
                      ? $t(`flight.airlines.${item.distributor.toUpperCase()}`)
                      : '' }}
                    ({{ $te(`flight.sourceName.${item.distributor.toUpperCase()}`)
                      ? $t(`flight.sourceName.${item.distributor.toUpperCase()}`)
                      : item.distributor.toUpperCase() }})
                  </span>
                </div>
              </template>

              <template #cell(feeType)="data">
                {{ $t(`fee.${data.item.feeType}`) }}
              </template>
              <template #cell(ticketType)="data">
                {{ $t(`fee.${data.item.ticketType}`) }}
              </template>
              <template #cell(amount)="data">
                <span class="fw-700">{{ formatCurrency(data.item.amount) }}</span>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </div>

      <div v-else>
        Chưa cài phí xuất vé cho F3
      </div>
    </b-card>
    <!-- !SECTION -->

    <!-- SECTION Retail -->
    <b-card
      v-if="isF3Retail || isRoleF1"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1 pb-0"
      class="border my-1"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('agency.configs.retailTitle') }}
        </h6>
      </template>
      <b-card-body class="p-0 d-flex-center justify-content-start mt-1">
        <b-row class="w-100 gap-3">
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.autoDeposit"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              :disabled="false"
              @click.native.prevent="updateSwitch('autoDeposit')"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.autoDeposit') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <b-col
            v-if="agencyIsF3"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.autoIssueTicket"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="updateSwitch('autoIssueTicket')"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.autoIssueTicket') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <!-- ANCHOR - F2 config cho F3 -->
          <!-- F3 bán lẻ -->
          <b-col
            v-if="isF3Retail"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.enableDraft"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="changeDisableDraft(agencyToEdit.enableDraft)"
            >
              <h5 class="mb-0 mt-25">
                {{ agencyToEdit.enableDraft ? $t('agency.configs.enableDraft') : $t('agency.configs.disableDraft') }}
              </h5>
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="isF3Retail && agencyToEdit.agencyRetailConfig"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.agencyRetailConfig.usePhoneCustomer"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="updateSwitch('usePhoneCustomer')"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.usePhoneCustomer') }}
              </h5>
            </b-form-checkbox>
          </b-col>

          <b-col
            v-if="isF3Retail && agencyToEdit.agencyRetailConfig"
            cols="12"
            md="auto"
            class="d-flex-center justify-content-start"
          >
            <b-form-checkbox
              :checked="agencyToEdit.agencyRetailConfig.showTicketCondition"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="updateSwitch('showTicketCondition')"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('agency.configs.showTicketCondition') }}
              </h5>
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="isF3Retail && agencyToEdit.agencyRetailConfig"
            cols="12"
          >
            <div class="d-sm-flex d-flex-col gap-1">
              <!-- ANCHOR payment method-->
              <b-form-group
                :label="$t('agency.configs.paymentMethod')"
                class="mb-1"
                :disabled="!isEditRetailConfig"
              >
                <b-form-checkbox
                  v-model="agencyToEdit.agencyRetailConfig.showPaymentOffice"
                  class="custom-control-success"
                  name="check-button"
                  inline
                >
                  {{ $t(`agency.configs.showPaymentOffice`) }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="agencyToEdit.agencyRetailConfig.showPaymentHome"
                  class="custom-control-success"
                  name="check-button"
                  inline
                >
                  {{ $t(`agency.configs.showPaymentHome`) }}
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="agencyToEdit.agencyRetailConfig.showPaymentQR"
                  class="custom-control-success"
                  name="check-button"
                  inline
                >
                  {{ $t(`agency.configs.showPaymentQR`) }}
                </b-form-checkbox>
              </b-form-group>

              <!-- Button Edit -->
              <div>
                <BButton
                  v-if="!isEditRetailConfig"
                  variant="outline-primary"
                  size="sm"
                  @click="isEditRetailConfig = !isEditRetailConfig"
                >
                  {{ $t('edit') }}
                </BButton>
                <BButton
                  v-else
                  variant="primary"
                  :disabled="loadingRetailConfig"
                  size="sm"
                  @click="handleEditRetailConfig"
                >
                  <b-spinner
                    v-if="loadingRetailConfig"
                    variant="light"
                    label="Text Centered"
                    small
                    tag="span"
                  />
                  {{ loadingRetailConfig ? $t('saving') : $t('save') }}
                </BButton>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- !SECTION -->

    <!-- SECTION Marketplace -->
    <b-card
      v-if="isRoleF1"
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1 pb-0"
      class="border my-1"
    >
      <template #header>
        <h6 class="m-0">
          {{ $t('resellTicket.form.titleSetting') }}
        </h6>
      </template>
      <b-card-body class="p-0 d-flex-center justify-content-start mt-1">
        <b-row class="w-100 gap-3">
          <b-col
            v-if="agencyIsF2"
            cols="12"
            md="auto"
          >
            <div
              v-if="new Date(agenciesData.expiredDateBanMkp) > new Date()"
              class="text-danger"
            >
              <span
                v-if="new Date(agenciesData.expiredDateBanMkp) - new Date() > 100 * 365.25 * 24 * 60 * 60 * 1000"
                class="text-danger"
              >
                Cấm vĩnh viễn
              </span>
              <span v-else>
                {{ $t('resellTicket.banTo') }}:
                {{ convertISODateTime(agenciesData.expiredDateBanMkp).dateTime }}
              </span>
            </div>
            <br>
            <b-form-checkbox
              :checked="new Date(agenciesData.expiredDateBanMkp) > new Date()"
              class="custom-control-success"
              name="check-button"
              switch
              inline
              @click.native.prevent="onBan"
            >
              <h5 class="mb-0 mt-25">
                {{ $t('resellTicket.btn.ban') }}
              </h5>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- !SECTION -->

    <ModalUpdateAgencyFee
      v-if="!isRoleF3"
      :agencies-data="agenciesData"
      :is-edit-place="true"
      @refetchAgency="refetchAgency"
    />

    <ModalDisableDraft
      v-if="agencyToEdit"
      :agency-to-edit="agencyToEdit"
      @update-enable-draft="updateEnableDraft"
    />
    <BanAGModal
      :ag-id="agenciesData?.id"
      @refresh="refetchAgency"
    />
  </section>
</template>

<script>
import {
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isNaN from 'lodash/isNaN'
import Ripple from 'vue-ripple-directive'

import { apiAgencies } from '@/api'
import { distributorsList } from '@/constants/selectOptions'
import store from '@/store'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

import useAgenciesHandle from '@agencies/useAgenciesHandle'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
    BTabs,
    BTab,
    BTable,
    BAlert,
    BSpinner,
    BFormInput,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    ModalUpdateAgencyFee: () => import('./ModalUpdateAgencyFee.vue'),
    ModalDisableDraft: () => import('./ModalDisableDraft.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    BanAGModal: () => import('@saleReport/resell-ticket/BanAGModal.vue'),
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    agenciesData: {
      type: Object,
      required: true,
    },
    loadingProp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const { toastError, toastSuccess } = useToast()

    const agencyToEdit = ref(JSON.parse(JSON.stringify(props.agenciesData)))
    const resetAgencyToEdit = () => {
      agencyToEdit.value = JSON.parse(JSON.stringify(props.agenciesData))
    }
    watch(() => props.agenciesData, () => {
      resetAgencyToEdit()
    })

    // for topup
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    const meDataType = computed(() => store.getters['userStore/getMeDataType'])
    const hasRoleBan = computed(() => ['ADM', 'OPE', 'SE'].includes(meDataType.value) && isRoleF1.value)

    // Không phải user đăng nhập + khác F1 và F2 -> Chỉ F3 //// update(16/04): cho phép F1 cài cho F2
    const showBookingLimit = computed(() => meData.value?.agency?.id !== props.agenciesData?.id)
    // Không phải user đăng nhập + khác F1 -> F2 hoặc F3
    const showIssueRefundVoidLimit = meData.value.agency.id !== props.agenciesData.id && props.agenciesData.id !== 1000000

    const isF3Retail = computed(() => props.agenciesData?.agencyCode.endsWith('-RTL'))

    const agencyIsF2 = computed(() => agencyToEdit?.value?.parentAgency?.id === 1000000)
    const agencyIsF1 = computed(() => agencyToEdit?.value?.id === 1000000)
    const agencyIsF3 = computed(() => !agencyIsF2.value && !agencyIsF1.value)

    const {
      loading,
      updateHideFeeConfig,
      updateSendMailAirline,
      updateSendPhoneAirline,
      updateEnableQuickName,
      updateEnableRebook,
      updateLimitPaxBooking,
      updateLimitTimeSearch,
      SetLowFareTracking,
      enableBookingGroup,
      updateAgencies,
    } = useAgenciesHandle()

    const {
      unbanAGByAGId,
    } = useResellTicketHandle()

    const bookingLimit = ref(cloneDeep(props.agenciesData.bookingLimit) || [])
    const changeBookingLimit = (checked, airlineSource) => {
      bookingLimit.value = bookingLimit.value.map(bLimit => {
        if (bLimit?.airlineSource === airlineSource) {
          bLimit.enableBooking = checked
        }
        return bLimit
      })
    }

    const issueLimit = ref(cloneDeep(props.agenciesData.issueLimit) || [])
    const changeIssueLimit = (checked, airlineSource) => {
      issueLimit.value = issueLimit.value.map(issue => {
        if (issue?.airlineSource === airlineSource) {
          issue.allowance = checked
        }
        return issue
      })
    }

    const refundLimit = ref(cloneDeep(props.agenciesData.refundLimit) || [])
    const changeRefundLimit = (checked, airlineSource) => {
      refundLimit.value = refundLimit.value.map(issue => {
        if (issue?.airlineSource === airlineSource) {
          issue.allowance = checked
        }
        return issue
      })
    }

    const voidLimit = ref(cloneDeep(props.agenciesData.voidLimit) || [])
    const changeVoidLimit = (checked, airlineSource) => {
      voidLimit.value = voidLimit.value.map(issue => {
        if (issue?.airlineSource === airlineSource) {
          issue.allowance = checked
        }
        return issue
      })
    }

    const distributorsRefundList = ['VN1A', 'VN1A_MT', 'VJ', 'QH', 'VU', '1G']
    const distributorsVoidList = ['VN1A', 'VN1A_MT', 'QH', '1G']
    watch(distributorsList, () => {
      if (distributorsList.value) {
        distributorsList.value.forEach(source => {
          if (!bookingLimit.value.find(({ airlineSource }) => airlineSource === source)) {
            bookingLimit.value.push({
              airlineSource: source,
              enableBooking: false,
            })
          }
          if (!issueLimit.value.find(({ airlineSource }) => airlineSource === source)) {
            issueLimit.value.push({
              airlineSource: source,
              allowance: false,
            })
          }
          if (!refundLimit.value.find(({ airlineSource }) => (airlineSource === source)) && (distributorsRefundList.includes(source))) {
            refundLimit.value.push({
              airlineSource: source,
              allowance: false,
            })
          }
          if (!voidLimit.value.find(({ airlineSource }) => (airlineSource === source)) && (distributorsVoidList.includes(source))) {
            voidLimit.value.push({
              airlineSource: source,
              allowance: false,
            })
          }
        })
      }
    }, { immediate: true, deep: true })

    function refetchAgency() {
      emit('refetchAgency')
    }

    function changeHideFeeConfig(id) {
      if (isF3Retail.value) return
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateHideFeeConfig(id)
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function changeActionSetLowFareTracking(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.enableSetLowFare') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            SetLowFareTracking(id)
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function toggleEnableBookingGroup(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.enableBookingGroup') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            enableBookingGroup(id)
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function changeSendMailAirline(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.sendMailAirlineOn') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateSendMailAirline(id, { sendMailAirline: !agencyToEdit.value.sendMailAirline })
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }
    function changeSendPhoneAirline(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.sendPhoneAirline') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateSendPhoneAirline(id, { sendPhoneAirline: !agencyToEdit.value.sendPhoneAirline })
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function changeEnableQuickName(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.enableQuickName') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateEnableQuickName(id)
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function changeEnableRebook(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.enableRebook') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateEnableRebook(id)
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function changeLimitTimeSearch(id, enable, seconds) {
      if (enable && seconds === 0) seconds = 30
      if ((isNaN(Number(seconds)) || Number(seconds) <= 0 || Number(seconds) > 30)) {
        if (enable) {
          toastError({ title: 'messagesList.error', content: 'Giới hạn thời gian trong khoảng: 1 ~ 30 giây' })
          return
        }

        seconds = 30 // default
      }
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.limitTimeSearch') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateLimitTimeSearch(id, {
              limitTimeSearch: {
                enable,
                seconds: Number(seconds),
              },
            })
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function changeLimitPaxBooking(id, enable, paxLength) {
      if ((isNaN(Number(paxLength)) || Number(paxLength) <= 0 || Number(paxLength) > 9)) {
        if (enable) {
          toastError({ title: 'messagesList.error', content: 'Giới hạn hành khách/booking trong khoảng: 1 ~ 9 khách/booking' })
          return
        }

        paxLength = 9 // default
      }
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatusWithText', { text: root.$t('agency.configs.limitPaxBooking') }), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            updateLimitPaxBooking(id, {
              limitPaxBooking: {
                enable,
                paxLength: Number(paxLength),
              },
            })
              .finally(() => {
                refetchAgency()
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function editAgencyFee() {
      this.$bvModal.show('modal-update-agency-fee')
    }

    const getDistributors = computed(() => store.getters['globalConfig/getDistributors'])
    function getItemsFee(feeLists, type) {
      Object.keys(getDistributors.value).forEach(key => {
        getDistributors.value[key].forEach(sourceItem => {
          if (feeLists.find(i => i.ticketType === key.toUpperCase() && i.airline === sourceItem)) return
          feeLists.push({
            airline: sourceItem,
            feeType: 'FLIGHT_ITINERARY',
            amount: 0,
            ticketType: key.toUpperCase(),
          })
        })
      })

      const result = feeLists.map(fee => ({
        distributor: distributorsList.value.find(dis => dis.value === fee.airline)?.label || fee.airline,
        feeType: fee.feeType,
        ticketType: fee.ticketType,
        amount: Number(fee.amount),
      })).filter(f => [type].includes(f.ticketType))

      return result
    }

    const tableColumns = ref(['no', 'paxUpdated', 'action', 'updatedData', 'updatedAt'])

    // edit retail config
    const isEditRetailConfig = ref(false)
    const loadingRetailConfig = ref(false)
    async function handleEditRetailConfig() {
      loadingRetailConfig.value = true
      if (!isEditRetailConfig.value) {
        return
      }

      const result = await apiAgencies.updateAgencies(props.agenciesData.id, { agencyRetailConfig: agencyToEdit.value.agencyRetailConfig })
      if (result) {
        refetchAgency()
        toastSuccess('Chỉnh sửa cài đặt quyền cho đại lý thành công')
      }

      loadingRetailConfig.value = false
      isEditRetailConfig.value = !isEditRetailConfig.value
    }

    // edit permission
    const isEditPermission = ref(false)
    const loadingEditPermission = ref(false)
    async function handleEditPermission() {
      loadingEditPermission.value = true
      if (!isEditPermission.value) {
        return
      }
      const promiseArray = []
      if (!isEqual(bookingLimit.value, props.agenciesData.bookingLimit)) {
        promiseArray.push(
          apiAgencies.updateBookingLimit(props.agenciesData.id, { bookingLimit: bookingLimit.value }),
        )
      }
      if (!isEqual(issueLimit.value, props.agenciesData.issueLimit)) {
        promiseArray.push(
          apiAgencies.updateIssueLimit(props.agenciesData.id, { issueLimit: issueLimit.value }),
        )
      }
      if (!isEqual(refundLimit.value, props.agenciesData.refundLimit) && agencyIsF2.value) {
        promiseArray.push(
          apiAgencies.updateRefundLimit(props.agenciesData.id, { refundLimit: refundLimit.value }),
        )
      }
      if (!isEqual(voidLimit.value, props.agenciesData.voidLimit) && agencyIsF2.value) {
        promiseArray.push(
          apiAgencies.updateVoidLimit(props.agenciesData.id, { voidLimit: voidLimit.value }),
        )
      }
      if (promiseArray.length) {
        const res = await Promise.all(promiseArray)
        refetchAgency()
        if (res.every(Boolean)) {
          toastSuccess('Chỉnh sửa cài đặt quyền cho đại lý thành công')
        }
      }
      loadingEditPermission.value = false
      isEditPermission.value = !isEditPermission.value
    }

    // retail
    function updateSwitch(field) {
      root.$bvModal
        .msgBoxConfirm(root.$t('modal.changeStatusWithText', { text: root.$t(`agency.configs.${field}`) }), {
          title: root.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: root.$t('modal.yes'),
          cancelTitle: root.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            root.$bvModal.show('modal-api-loading')
            const payload = ['usePhoneCustomer', 'showTicketCondition'].includes(field)
              ? {
                agencyRetailConfig: {
                  ...agencyToEdit.value.agencyRetailConfig,
                  [field]: !agencyToEdit.value.agencyRetailConfig[field],
                },
              }
              : { [field]: !agencyToEdit.value[field] }
            updateAgencies(agencyToEdit.value, payload, true)
              .finally(() => {
                refetchAgency()
                root.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function updateEnableDraft(value) {
      agencyToEdit.value.enableDraft = value
      root.$bvModal.show('modal-api-loading')
      const payload = { enableDraft: value }
      updateAgencies(agencyToEdit.value, payload, true)
        .finally(() => {
          refetchAgency()
          root.$bvModal.hide('modal-api-loading')
        })
    }

    async function changeDisableDraft(enableDraft) {
      if (enableDraft) {
        root.$bvModal.show('modal-disable-draft')
      } else if (!enableDraft) {
        updateSwitch('enableDraft')
      }
    }

    const isShowChangeFeeAgencyConfigBtn = computed(() => {
      if (isRoleF1.value) return ['ADM', 'OPE'].includes(meData.value.type)
      return isRoleF2.value
    })

    function onBan() {
      if (new Date(props.agenciesData.expiredDateBanMkp) < new Date()) {
        this.$root.$emit(
          'bv::show::modal',
          'ban-ag-modal',
        )
      } else {
        const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmUnban') } })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: this.$t('modal.confirmUnban'),
            size: 'sm',
            okVariant: 'info',
            okTitle: this.$t('modal.yes'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              unbanAGByAGId(props.agenciesData?.id).then(() => {
                refetchAgency()
              })
            }
          })
      }
    }
    return {
      agencyToEdit,
      loading,
      showIssueRefundVoidLimit,
      changeIssueLimit,
      issueLimit,
      showBookingLimit,
      changeBookingLimit,
      bookingLimit,
      refundLimit,
      changeRefundLimit,
      voidLimit,
      changeVoidLimit,
      formatCurrency,
      changeHideFeeConfig,
      changeSendMailAirline,
      changeSendPhoneAirline,
      changeEnableQuickName,
      changeEnableRebook,
      changeLimitPaxBooking,
      changeLimitTimeSearch,
      changeActionSetLowFareTracking,
      toggleEnableBookingGroup,
      isRoleF1,
      isRoleF2,
      isRoleF3,
      refetchAgency,
      editAgencyFee,
      getItemsFee,
      tableColumns,
      isF3Retail,
      changeDisableDraft,
      updateEnableDraft,
      agencyIsF1,
      agencyIsF2,
      agencyIsF3,
      isEditPermission,
      handleEditPermission,
      loadingEditPermission,
      updateSwitch,
      isEditRetailConfig,
      loadingRetailConfig,
      handleEditRetailConfig,
      isShowChangeFeeAgencyConfigBtn,
      hasRoleBan,
      convertISODateTime,
      onBan,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.url-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

.button_actions {
  position: sticky;
  z-index: 22;
  bottom: 10px;
}
</style>
